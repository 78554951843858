import axios from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";
import type { ReadMarkerEntity } from "../types/readMarkers";
import type { MessageResult, Result } from "../types/result";

export class ReadMarker {
  static async getReadMarkersForTitle(titleId: string, auth: string) {
    const resp = await axios<Result<ReadMarkerEntity>>(
      `/title/${titleId}/read`,
      {
        method: "GET",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async setReadMarkers(
    auth: string,
    titleId: string,
    read: string[] = [],
    unread: string[] = [],
    time?: string,
  ): Promise<string> {
    if (unread.length === 0 && read.length === 0) return "Read Markers applied";
    const resp = await axios<MessageResult<"Read Markers applied">>(
      `/title/${titleId}/read`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: {
          add: read,
          remove: unread,
          time,
        },
      },
    );

    return assertSuccess(resp.data).message;
  }
}
